import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import MiniLawnChair from '../../images/miniLawnChair.svg'

const LawnAeration = () => {
	const TheTitle = "Lawn Aeration Services"
	const TheDescription = "Over time grass clippings and other plant material build up, which in itself can be very beneficial, but without proper tending the layer will grow too thick preventing water, sun, air and nutrients from reaching the roots of the grass. This can encourage moss to grow and choke out what would otherwise be a healthy lawn. We have two primary ways to resolve this. Our trained staff will be able to assess which way would be best for your lawn."
	const TheCanonical = "https://gardenescape.ca/services/lawn-aeration/"

	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />
			<div className="serviceGrid">
				<StaticImage
					className="serviceGrid_boxA"
					src="../../images/home/lawnChairs.png"
					alt="background placeholder"
					layout="fullWidth" />
				<div className="serviceGrid_boxB">
					<h1>{TheTitle}</h1>
					<p className="serviceGrid_boxB_text">25% off your first mow</p>
					<img
						src={MiniLawnChair}
						width="75px"
						height="75px"
						alt="garden escape mini logo" />
				</div>
			</div>
			<div className="mainWrapper my-16">
			<h2>WHAT’S THE BEST WAY TO HELP MY LAWN GROW?</h2>
			<p>Over time grass clippings and other plant material build up, which in itself can be very beneficial, but without proper tending the layer will grow too thick preventing water, sun, air and nutrients from reaching the roots of the grass. This can encourage moss to grow and choke out what would otherwise be a healthy lawn. We have two primary ways to resolve this. Our trained staff will be able to assess which way would be best for your lawn.</p>

			<p>Power Raking is a way to very effectively break down that overly thick layer of thatch by running this powered thatch rake over the lawn to rip up the unwanted layer making it easy to remove by hand.</p>

			<p>When we Aerate a lawn we use a piece of equipment to perforate the lawn by removing soil cores at regular intervals to give water, air and nutrients access to deep into the lawn making it easy for the grass to access. This easy access to the roots helps the lawn grow lush and strong quickly.</p>

			<h2>HOW DO I PROGRESS?</h2>
			<p>Contact us! We would be happy to provide you an obligation free estimate! We’ll put together a plan that’ll best fit your yard goals and budget. Just contact us through our website, phone or email.</p>

			<h2>OTHER WAYS WE CAN SERVE YOU.</h2>
				<p>We are happy to help you with all things related to beautifying your property, some other major services we offer are; Regular lawn mow, fertilizing, Seasonal Yard cleanup, Hedge and Shrub pruning, Power washing and Junk Removal.</p>
			</div>
		</Layout>
	)
}

export default LawnAeration